import Container from '@mui/material/Container'

type WorkoutLayoutProps = {
  children: React.ReactNode
}

const WorkoutsLayout = ({ children }: WorkoutLayoutProps) => {
  return <Container maxWidth="xl">{children}</Container>
}

export default WorkoutsLayout
