import React from 'react'
import { useAuth } from '@redwoodjs/auth'
import { navigate, routes } from '@redwoodjs/router'

import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'

const LandingLayout: React.FunctionComponent = ({ children }) => {
  const { logOut, isAuthenticated } = useAuth()

  const signUpButton = (
    <Button color="inherit" onClick={() => navigate(routes.signUp())}>
      <Typography variant="h6">Signup</Typography>
    </Button>
  )

  const Header: React.FC = () => {
    return (
      <AppBar id="landing-page-header" position="sticky">
        <Toolbar>
          <Button
            color="inherit"
            onClick={isAuthenticated ? logOut : () => navigate(routes.logIn())}
          >
            <Typography variant="h6">
              {isAuthenticated ? 'LOGOUT' : 'LOGIN'}
            </Typography>
          </Button>
          {isAuthenticated ? '' : signUpButton}
        </Toolbar>
      </AppBar>
    )
  }

  return (
    <Box id="landing-page-layout">
      <Header />
      <Container id="landing-page-content" fixed>
        {children}
      </Container>
    </Box>
  )
}

export default LandingLayout
