import React from 'react'
import AccessibilityNewRoundedIcon from '@mui/icons-material/AccessibilityNewRounded'
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded'
import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded'
import FitnessCenterRoundedIcon from '@mui/icons-material/FitnessCenterRounded'
import HelpRoundedIcon from '@mui/icons-material/HelpRounded'
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded'
import DirectionsRunRoundedIcon from '@mui/icons-material/DirectionsRunRounded'

enum Displays {
  Dashboard,
  Workouts,
  Gym,
  Exercises,
  Muscles,
  Body,
  Help,
  Settings,
}

type SidebarMenuItems = {
  display: Displays
  name: string
  icon: JSX.Element
  path: string
  top: boolean
}[]

const sidebarMenuItems: SidebarMenuItems = [
  {
    display: Displays.Dashboard,
    name: 'Dashboard',
    icon: <DashboardRoundedIcon />,
    path: '/admin/dashboard',
    top: true,
  },
  {
    display: Displays.Workouts,
    name: 'Workouts',
    icon: <FormatListBulletedRoundedIcon />,
    path: '/admin/workouts',
    top: true,
  },
  {
    display: Displays.Exercises,
    name: 'Exercises',
    icon: <FitnessCenterRoundedIcon />,
    path: '/admin/strength-exercises',
    top: true,
  },
  {
    display: Displays.Gym,
    name: 'Gym',
    icon: <DirectionsRunRoundedIcon />,
    path: '/admin/gyms',
    top: true,
  },
  {
    display: Displays.Muscles,
    name: 'Muscles',
    icon: <AccessibilityNewRoundedIcon />,
    path: '/admin/muscles',
    top: true,
  },
  {
    display: Displays.Settings,
    name: 'Settings',
    icon: <SettingsRoundedIcon />,
    path: '/admin/settings',
    top: false,
  },
]

export { sidebarMenuItems, SidebarMenuItems }
