import React, { useState } from 'react'

import { navigate, useLocation } from '@redwoodjs/router'
import { useAuth } from '@redwoodjs/auth'

import AppBar from '@mui/material/AppBar'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import MenuIcon from '@mui/icons-material/Menu'
import { useTheme } from '@mui/material/styles'
import { useMediaQuery } from '@mui/material'

import {
  sidebarMenuItems,
  SidebarMenuItems,
} from 'src/layouts/PrimaryLayout/sidebarMenuItems'

const drawerWidthMobile = 240
const drawerWidthNotMobile = 170

type PrimaryLayoutProps = {
  children: React.ReactNode
}

const PrimaryLayout: React.FC<PrimaryLayoutProps> = ({ children }) => {
  const { logIn, logOut, isAuthenticated } = useAuth()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const { pathname: activeDisplay } = useLocation()

  const handleDrawerClick = (display: string) => {
    setIsDrawerOpen(false)
    navigate(display)
  }

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen)
  }

  const getDrawerWidth = () => {
    return isMobile ? drawerWidthMobile : drawerWidthNotMobile
  }

  const header: JSX.Element = (
    <AppBar
      position="sticky"
      sx={{
        width: {
          md: `calc(100% - ${getDrawerWidth()}px)`,
        },
        ml: { md: `${getDrawerWidth()}px` },
      }}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{
            mr: 2,
            display: { md: 'none' },
          }}
        >
          <MenuIcon />
        </IconButton>
        <Button color="inherit" onClick={isAuthenticated ? logOut : logIn}>
          <Typography variant="h6">
            {isAuthenticated ? 'LOGOUT' : 'LOGIN'}
          </Typography>
        </Button>
      </Toolbar>
    </AppBar>
  )

  const listItems = (items: SidebarMenuItems): JSX.Element[] => {
    return items.map((listItem) => (
      <ListItem
        key={listItem.name}
        button
        divider
        selected={activeDisplay === listItem.path}
        onClick={() => {
          handleDrawerClick(listItem.path)
        }}
      >
        {listItem.icon}
        <ListItemText primary={listItem.name} />
      </ListItem>
    ))
  }

  const sidebarNav: JSX.Element = (
    <>
      {/*necessary for content to be below app bar*/}
      <Toolbar />
      <Divider />
      <List disablePadding>{listItems(sidebarMenuItems)}</List>
    </>
  )

  const mobileSidebar: JSX.Element = (
    <Box
      component="nav"
      sx={{ width: { md: getDrawerWidth() }, flexShrink: { md: 0 } }}
      aria-label="display picker"
    >
      <Drawer
        variant="temporary"
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={isDrawerOpen}
        sx={{
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidthMobile,
          },
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        {sidebarNav}
      </Drawer>
    </Box>
  )

  const tabletPlusSidebar: JSX.Element = (
    <Box
      component="nav"
      sx={{ width: { md: getDrawerWidth() }, flexShrink: { md: 0 } }}
      aria-label="display picker"
    >
      <Drawer
        variant="permanent"
        open
        sx={{
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidthNotMobile,
          },
        }}
      >
        {sidebarNav}
      </Drawer>
    </Box>
  )

  return (
    <Box>
      {header}
      {isMobile ? mobileSidebar : tabletPlusSidebar}
      <Box
        component="main"
        sx={{
          ml: { md: `${getDrawerWidth()}px` },
          mt: { xs: '10px', sm: '20px' },
        }}
      >
        {children}
      </Box>
    </Box>
  )
}

export { PrimaryLayout, PrimaryLayoutProps }
