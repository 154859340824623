import React from 'react'

import Container from '@mui/material/Container'
import Box from '@mui/material/Box'

import Copyright from 'src/components/Copyright'

type AuthenticationLayoutProps = {
  children?: React.ReactNode
}

const AuthenticationLayout = ({ children }: AuthenticationLayoutProps) => {
  return (
    <Container component="main" maxWidth="xs">
      <Box paddingTop={{ xs: 3, sm: 6, md: 8, lg: 10, xl: 12 }}>{children}</Box>
      <Box marginTop={{ xs: 4, sm: 6, md: 8 }}>
        <Copyright />
      </Box>
    </Container>
  )
}

export default AuthenticationLayout
