import Container from '@mui/material/Container'

type GymLayoutProps = {
  children: React.ReactNode
}

const GymsLayout = ({ children }: GymLayoutProps) => {
  return <Container maxWidth="xl">{children}</Container>
}

export default GymsLayout
