import { RoleName } from '@prisma/client'
import { Router, Route, Set, Private } from '@redwoodjs/router'
import FreeWeightsLayout from 'src/layouts/FreeWeightsLayout'
import EquipmentsLayout from 'src/layouts/EquipmentsLayout'

import { PrimaryLayout } from 'src/layouts/PrimaryLayout/PrimaryLayout'
import LandingLayout from 'src/layouts/LandingLayout'
import AuthenticationLayout from 'src/layouts/AuthenticationLayout'
import GymsLayout from 'src/layouts/GymsLayout'
import WorkoutsLayout from 'src/layouts/WorkoutsLayout'
import StrengthExerciseSetsLayout from 'src/layouts/StrengthExerciseSetsLayout'
import StrengthExercisesLayout from 'src/layouts/StrengthExercisesLayout'
import MusclesLayout from 'src/layouts/MusclesLayout'
import { PrimaryLayout as PrimaryAdminLayout } from 'src/layouts/admin/PrimaryLayout/PrimaryLayout'
import GymsAdminLayout from 'src/layouts/admin/GymsLayout'
import WorkoutsAdminLayout from 'src/layouts/admin/WorkoutsLayout'
import StrengthExerciseSetsAdminLayout from 'src/layouts/admin/StrengthExerciseSetsLayout'
import StrengthExercisesAdminLayout from 'src/layouts/admin/StrengthExercisesLayout'
import MusclesAdminLayout from 'src/layouts/admin/MusclesLayout'

const Routes = () => {
  return (
    <Router>
      <Set wrap={LandingLayout}>
        <Route path="/" page={LandingPage} name="landing" />
      </Set>

      <Set wrap={AuthenticationLayout}>
        <Route path="/log-in" page={LogInPage} name="logIn" />
        <Route path="/sign-up" page={SignUpPage} name="signUp" />
      </Set>

      <Private unauthenticated="landing" role={RoleName.USER}>
        <Set wrap={PrimaryLayout}>
          <Route path="/dashboard" page={DashboardPage} name="dashboard" />

          <Set wrap={WorkoutsLayout}>
            <Route path="/workouts/new" page={WorkoutNewWorkoutPage} name="newWorkout" />
            <Route path="/workouts/{id}/edit" page={WorkoutEditWorkoutPage} name="editWorkout" />
            <Route path="/workouts/{id}" page={WorkoutWorkoutPage} name="workout" />
            <Route path="/workouts" page={WorkoutWorkoutsPage} name="workouts" />
          </Set>

          <Set wrap={StrengthExercisesLayout}>
            <Route path="/strength-exercises/new" page={StrengthExerciseNewStrengthExercisePage} name="newStrengthExercise" />
            <Route path="/strength-exercises/{id}/edit" page={StrengthExerciseEditStrengthExercisePage} name="editStrengthExercise" />
            <Route path="/strength-exercises/{id}" page={StrengthExerciseStrengthExercisePage} name="strengthExercise" />
            <Route path="/strength-exercises" page={StrengthExerciseStrengthExercisesPage} name="strengthExercises" />
          </Set>

          <Set wrap={StrengthExerciseSetsLayout}>
            <Route path="/strength-exercise-sets/new" page={StrengthExerciseSetNewStrengthExerciseSetPage} name="newStrengthExerciseSet" />
            <Route path="/strength-exercise-sets/{id}/edit" page={StrengthExerciseSetEditStrengthExerciseSetPage} name="editStrengthExerciseSet" />
            <Route path="/strength-exercise-sets/{id}" page={StrengthExerciseSetStrengthExerciseSetPage} name="strengthExerciseSet" />
            <Route path="/strength-exercise-sets" page={StrengthExerciseSetStrengthExerciseSetsPage} name="strengthExerciseSets" />
          </Set>

          <Set wrap={GymsLayout}>
            <Route path="/gyms/new" page={GymNewGymPage} name="newGym" />
            <Route path="/gyms/{id}/edit-equipment" page={GymEditGymEquipmentPage} name="editGymEquipment" />
            <Route path="/gyms/{id}/edit-freeweights" page={GymEditGymFreeWeightsPage} name="editGymFreeWeights" />
            <Route path="/gyms/{id}" page={GymGymPage} name="gym" />
            <Route path="/gyms" page={GymGymsPage} name="gyms" />
          </Set>

          <Set wrap={MusclesLayout}>
            <Route path="/muscles/new" page={MuscleNewMusclePage} name="newMuscle" />
            <Route path="/muscles/{id}/edit" page={MuscleEditMusclePage} name="editMuscle" />
            <Route path="/muscles/{id}" page={MuscleMusclePage} name="muscle" />
            <Route path="/muscles" page={MuscleMusclesPage} name="muscles" />
          </Set>

          <Set wrap={EquipmentsLayout}>
            <Route path="/equipments/new" page={EquipmentNewEquipmentPage} name="newEquipment" />
            <Route path="/equipments/{id}/edit" page={EquipmentEditEquipmentPage} name="editEquipment" />
            <Route path="/equipments/{id}" page={EquipmentEquipmentPage} name="equipment" />
            <Route path="/equipments" page={EquipmentEquipmentsPage} name="equipments" />
          </Set>

          <Set wrap={FreeWeightsLayout}>
            <Route path="/free-weights/new" page={FreeWeightNewFreeWeightPage} name="newFreeWeight" />
            <Route path="/free-weights/{id}/edit" page={FreeWeightEditFreeWeightPage} name="editFreeWeight" />
            <Route path="/free-weights/{id}" page={FreeWeightFreeWeightPage} name="freeWeight" />
            <Route path="/free-weights" page={FreeWeightFreeWeightsPage} name="freeWeights" />
          </Set>

          <Route path="/settings" page={SettingsPage} name="settings" />
          <Route path="/help" page={HelpPage} name="help" />
        </Set>
      </Private>

      {/*Admin Pages*/}
      <Private unauthenticated="landing" role={RoleName.ADMIN}>
        <Set wrap={PrimaryAdminLayout}>
          <Route path="/admin/dashboard" page={adminDashboardPage} name="dashboardAdmin" />

          <Set wrap={WorkoutsAdminLayout}>
            <Route path="/admin/workouts/new" page={adminWorkoutNewWorkoutPage} name="newWorkoutAdmin" />
            <Route path="/admin/workouts/{id}/edit" page={adminWorkoutEditWorkoutPage} name="editWorkoutAdmin" />
            <Route path="/admin/workouts/{id}" page={adminWorkoutWorkoutPage} name="workoutAdmin" />
            <Route path="/admin/workouts" page={adminWorkoutWorkoutsPage} name="workoutsAdmin" />
          </Set>

          <Set wrap={StrengthExercisesAdminLayout}>
            <Route path="/admin/strength-exercises/new" page={adminStrengthExerciseNewStrengthExercisePage} name="newStrengthExerciseAdmin" />
            <Route path="/admin/strength-exercises/{id}/edit" page={adminStrengthExerciseEditStrengthExercisePage} name="editStrengthExerciseAdmin" />
            <Route path="/admin/strength-exercises/{id}" page={adminStrengthExerciseStrengthExercisePage} name="strengthExerciseAdmin" />
            <Route path="/admin/strength-exercises" page={adminStrengthExerciseStrengthExercisesPage} name="strengthExercisesAdmin" />
          </Set>

          <Set wrap={StrengthExerciseSetsAdminLayout}>
            <Route path="/admin/strength-exercise-sets/new" page={adminStrengthExerciseSetNewStrengthExerciseSetPage} name="newStrengthExerciseSetAdmin" />
            <Route path="/admin/strength-exercise-sets/{id}/edit" page={adminStrengthExerciseSetEditStrengthExerciseSetPage} name="editStrengthExerciseSetAdmin" />
            <Route path="/admin/strength-exercise-sets/{id}" page={adminStrengthExerciseSetStrengthExerciseSetPage} name="strengthExerciseSetAdmin" />
            <Route path="/admin/strength-exercise-sets" page={adminStrengthExerciseSetStrengthExerciseSetsPage} name="strengthExerciseSetsAdmin" />
          </Set>

          <Set wrap={GymsAdminLayout}>
            <Route path="/admin/gyms/new" page={adminGymNewGymPage} name="newGymAdmin" />
            <Route path="/admin/gyms/{id}/edit" page={adminGymEditGymPage} name="editGymAdmin" />
            <Route path="/admin/gyms/{id}" page={adminGymGymPage} name="gymAdmin" />
            <Route path="/admin/gyms" page={adminGymGymsPage} name="gymsAdmin" />
          </Set>

          <Set wrap={MusclesAdminLayout}>
            <Route path="/admin/muscles/new" page={adminMuscleNewMusclePage} name="newMuscleAdmin" />
            <Route path="/admin/muscles/{id}/edit" page={adminMuscleEditMusclePage} name="editMuscleAdmin" />
            <Route path="/admin/muscles/{id}" page={adminMuscleMusclePage} name="muscleAdmin" />
            <Route path="/admin/muscles" page={adminMuscleMusclesPage} name="musclesAdmin" />
          </Set>

          <Route path="/admin/settings" page={adminSettingsPage} name="settingsAdmin" />
        </Set>
      </Private>

      <Route notfound page={NotFoundPage} />
    </Router>
  )
}

export default Routes
